import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Card from '../../components/Card';

import Title from '../../components/Title';
import Button from '../../components/Button';
import Option from '../../components/Option';
import { useFormik, FormikProvider } from 'formik';
import Table from '../../components/BasicTable';
import { cnpjFormatter } from '../../utils/textFormatter';
import { Form, Input, Select } from 'formik-antd';
import InputMask from 'react-text-mask';
import { cnpjMask } from '../../utils/masks';

import { isArray } from 'lodash';

import { ReactComponent as FilterIcon } from '../../assets/images/icons/filter.svg';
import { ReactComponent as IconPlusBtn } from '../../assets/images/icons/add.svg';
import { ReactComponent as IconSearch } from '../../assets/images/icons/search.svg';
import { ReactComponent as IconErase } from '../../assets/images/icons/erase.svg';
import { ReactComponent as IconArrowSelect } from '../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as IconEditar } from '../../assets/images/icons/icon-editar.svg';
import './styles.scss';
import useInspinia from '../../hooks/useInspinia';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';
import { CredenciadoContext } from '../../contexts/CadastroCredenciamentoContext';
import removeChars from '../../utils/removeChars';

function Credenciados() {
  const { checarPermissao } = useContext(ControleAcessoContext);
  const {
    dadosCredenciamentoContext,
    pesquisarCredenciado,
    listaPesquisa,
    loadContextCredenciado,
    propsListagem,
  } = useContext(CredenciadoContext);

  useEffect(() => {
    loadContextCredenciado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInspinia();

  const history = useHistory();

  const [showFilter, setShowFilter] = useState(true);

  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({});

  const [whenChangePage, setWhenChangePage] = useState({
    pageIndex: 0,
    sortBy: [{ id: '', desc: true }],
  });

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const initialValues = {
    ativo: null,
    filtroCnpj: null,
    filtroNome: null,
    filtroStatus: null,
    filtroSubTipoCredenciado: null,
    filtroTipoCredenciado: null,
  };
  const formik = useFormik({
    initialValues,
    onSubmit: () => setFilters(formik.values),
  });

  const { values, setFieldValue, submitForm } =
    formik;

  const columns = [
    {
      Header: 'CNPJ',
      accessor: 'cpfcnpj',
      disableSortBy: false,
      Cell: ({ value }) => (value ? cnpjFormatter(value) : ''),
    },
    {
      Header: 'Nome',
      accessor: 'nome',
      disableSortBy: false,
    },
    {
      Header: 'Tipo',
      accessor: 'tipo',
      disableSortBy: false,
    },
    {
      Header: 'SubTipo',
      accessor: 'subTipo',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
      Cell: ({ value, row }) => (
        <Button
          style={{
            backgroundColor: dadosCredenciamentoContext?.status?.find(
              (item) => item.nome.toLowerCase() === value.toLowerCase(),
            ).cor,
          }}
          className="btn-status-listagem"
          type="button"
          onClick={() => history.push(`/dados-credenciado/${row.original.id}`)}
        >
          {value}
        </Button>
      ),
    },
    {
      Header: '',
      accessor: 'editar',
      disableSortBy: true,
      Cell: ({ row }) =>
        checarPermissao(
          'botao',
          'botao.cadastro.credenciamento.editar',
          'Visualizar',
        ) ? (
          <div className="form-button">
            <Button
              className="btn-icon-editar-list"
              disabled={
                !checarPermissao(
                  'botao',
                  'botao.cadastro.credenciamento.editar',
                  'Visualizar',
                )
              }
              onClick={() =>
                history.push(`/dados-credenciado/${row.original.id}/edicao`)
              }
            >
              <IconEditar />
            </Button>
          </div>
        ) : (
          <></>
        ),
    },
  ];

  async function formatarPesquisa(pesquisa) {
    let filteredValues = JSON.parse(JSON.stringify(pesquisa));

    if (filteredValues?.filtroCnpj) {
      filteredValues.filtroCnpj = removeChars(
        ['.', '-', '/', '_'],
        filteredValues.filtroCnpj,
      );
    }

    if (filteredValues.filtroTipoCredenciado === null) {
      delete filteredValues.filtroTipoCredenciado;
    } else if (filteredValues.filtroTipoCredenciado !== undefined) {
    filteredValues.filtroTipoCredenciado = [
    filteredValues.filtroTipoCredenciado];
    }

    if (filteredValues?.ativo === 1) {
      filteredValues.ativo = true;
    } else if (filteredValues?.ativo === 0) {
      filteredValues.ativo = false;
    }
    
    Object.keys(filteredValues).forEach((key) => {
      if (
        filteredValues[key] === null ||
        filteredValues[key] === undefined ||
        (isArray(filteredValues[key]) && filteredValues[key].length === 0)
      ) {
        delete filteredValues[key];
      }
    });
    return filteredValues;
  }

  async function handleSubmit(firstPage = false) {
    setLoading(true);
    const filteredValues = await formatarPesquisa({
      ...values,
      pagina: firstPage ? 1 : whenChangePage.pageIndex + 1, // se o filtro mudou, volta para a página 1
      ordenacao: whenChangePage?.sortBy[0]?.id,
      ordenacaoAsc: !whenChangePage?.sortBy[0]?.desc,
    });
    await pesquisarCredenciado(filteredValues);
    setLoading(false);
    if (firstPage) {
      setWhenChangePage({ ...whenChangePage, pageIndex: 0 });
    }
  }

  useEffect(() => {
    if (whenChangePage) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whenChangePage]);

  return (
    <>
      {checarPermissao(
        'tela',
        'tela.cadastro.credenciamento',
        'Visualizar',
      ) && (
        <>
          <FormikProvider value={formik}>
            <div className="card-listagem-credenciados">
              <Card
                title={<Title label="Credenciados" />}
                rightBar={
                  <div>
                    {checarPermissao(
                      'botao',
                      'botao.cadastro.credenciamento.maisCredenciado',
                      'Visualizar',
                    ) && (
                      <Button
                        onClick={() => history.push('/dados-credenciado')}
                        type="button"
                        variant="blue"
                        className="btn-adicionar-credenciado"
                        disabled={
                          !checarPermissao(
                            'botao',
                            'botao.cadastro.credenciamento.maisCredenciado',
                            'Visualizar',
                          )
                        }
                      >
                        <IconPlusBtn />
                        CREDENCIADO
                      </Button>
                    )}
                    <Button
                      onClick={handleShowFilter}
                      type="button"
                      variant="gray"
                      className="btn-filtrar"
                    >
                      <FilterIcon />
                      FILTRAR
                    </Button>
                  </div>
                }
              >
                {showFilter && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="box-filtros">
                        <Form layout="vertical">
                          <div className="row">
                            <div className="col-itens-filter">
                              <div className="filter-grid">
                                <div className="item-filter">
                                  <Form.Item label="CNPJ" name="filtroCnpj">
                                    <InputMask
                                      mask={cnpjMask}
                                      className="ant-input"
                                      placeholder="00.000.000/0000-00"
                                      onChange={(e) => {
                                        setFieldValue(
                                          'filtroCnpj',
                                          e.target.value,
                                        );
                                      }}
                                      value={formik.values.filtroCnpj}
                                    />
                                  </Form.Item>
                                </div>

                                <div className="item-filter">
                                  <Form.Item label="Nome" name="filtroNome">
                                    <Input type="text" name="filtroNome" />
                                  </Form.Item>
                                </div>

                                <div className="item-filter">
                                  <Form.Item label="Status" name="filtroStatus">
                                    <Select
                                      name="filtroStatus"
                                      placeholder="Selecione"
                                      suffixIcon={<IconArrowSelect />}
                                      mode="multiple"
                                      allowClear
                                      getPopupContainer={(trigger) => trigger.parentNode}
                                    >
                                      {dadosCredenciamentoContext?.status?.map(
                                        (item) => (
                                          <Option key={item.id} value={item.id}>
                                            {item?.nome}
                                          </Option>
                                        ),
                                      )}
                                    </Select>
                                  </Form.Item>
                                </div>
                                <div className="item-filter">
                                  <Form.Item
                                    label="Tipo"
                                    name="filtroTipoCredenciado"
                                  >
                                    <Select
                                      name="filtroTipoCredenciado"
                                      placeholder="Selecione"
                                      suffixIcon={<IconArrowSelect />}
                                      getPopupContainer={(trigger) => trigger.parentNode}
                                      allowClear
                                      value={values.filtroTipoCredenciado}
                                      onChange={(value) => {
                                        setFieldValue(
                                          'filtroTipoCredenciado',
                                          value,
                                        );
                                        setFieldValue(
                                          'filtroSubTipoCredenciado',
                                          [],
                                        );
                                      }}
                                    >
                                      {dadosCredenciamentoContext?.tipoCredenciado?.map(
                                        (item) => (
                                          <Option key={item.id} value={item.id}>
                                            {item.nome}
                                          </Option>
                                        ),
                                      )}
                                    </Select>
                                  </Form.Item>
                                </div>
                                <div className="item-filter">
                                  <Form.Item
                                    label="Subtipo"
                                    name="filtroSubTipoCredenciado"
                                  >
                                    <Select
                                      name="filtroSubTipoCredenciado"
                                      placeholder="Selecione"
                                      suffixIcon={<IconArrowSelect />}
                                      mode="multiple"
                                      allowClear
                                      getPopupContainer={(trigger) => trigger.parentNode}
                                    >
                                      {dadosCredenciamentoContext?.subTipoCredenciado
                                        ?.filter((item) => {
                                          return (
                                            item.tipoCredenciado ===
                                            values.filtroTipoCredenciado
                                          );
                                        })
                                        ?.map((item) => (
                                          <Option key={item.id} value={item.id}>
                                            {item.nome}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                </div>
                                <div className="item-filter">
                                  <Form.Item label="Ativo" name="ativo">
                                    <Select
                                      name="ativo"
                                      placeholder="Selecione"
                                      suffixIcon={<IconArrowSelect />}
                                      allowClear
                                      getPopupContainer={(trigger) => trigger.parentNode}
                                    >
                                      <Option key={1} value={true}>
                                        Ativo
                                      </Option>
                                      <Option key={2} value={false}>
                                        Inativo
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                            <div className="col-buttons">
                              <Button
                                type="button"
                                className="btn-pesquisar"
                                variant="blue"
                                async={true}
                                onClick={() => {
                                  submitForm(true);
                                }}
                              >
                                <IconSearch />
                                Pesquisar
                              </Button>
                              <Button
                                type="button"
                                className="btn-limpar"
                                variant="gray"
                                async={true}
                                onClick={() => {
                                  formik.setValues(initialValues);
                                }}
                              >
                                <IconErase />
                                Limpar
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                )}
                <div className="tabela-listagem-credenciados">
                  <Table
                    columns={columns}
                    data={listaPesquisa}
                    filter={filters}
                    loading={loading}
                    onLoadData={setWhenChangePage}
                    total={propsListagem.total}
                    pageCount={propsListagem.totalPages}
                  />
                </div>
              </Card>
            </div>
          </FormikProvider>
        </>
      )}
    </>
  );
}

export default Credenciados;
