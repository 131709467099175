import React, { useContext } from 'react';

import CardProduto from '../../../components/CardProduto';
import Overlay from '../../../components/Overlay';

import { OfertasProdutosContext } from '../../../contexts/OfertasProdutosContext';

function OfertasProdutosList() {
  const { setProduto, renda, setTouchedRenda, produtos, loadingList, produto, setDiaRecebimento } =
    useContext(OfertasProdutosContext);

  const simularPropostaProduto = (produto) => {
    if (renda > 0) {
      setProduto(produto);

      // Improvement 8338 - Dia recebimento passa a ser enviado dentro do array de cada produto, exceto para débito em conta que é enviado dentro de cada órgão
      if(produto.tipoCalculoVencimento === 5){
        if(produto?.orgao?.length === 1){
          setDiaRecebimento(produto?.orgao[0]?.diaRecebimento)
        }
      }
      else{
        setDiaRecebimento(produto?.diaRecebimento)
      }
      
    } else {
      setTouchedRenda(true);
    }
  };

  return (
    <Overlay active={loadingList}>
      <div className="produtos">
        {produtos.length > 0 &&
          produtos.map((item) => (
            <CardProduto
              key={item.id}
              disabled={item.id === produto?.id}
              selecionarProduto={async () => {
                if (item?.id !== produto?.id) {
                  await setProduto(null);
                  simularPropostaProduto(item);
                }
              }}
              produto={item}
            />
          ))}
      </div>
    </Overlay>
  );
}

export default OfertasProdutosList;
